import { DeleteIcon, SmileySadIcon } from '@mybridge/icons';
import { Divider } from '@mybridge/ui/divider';
import { Heading } from '@mybridge/ui/heading';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { requestFollow } from 'packages/mybridgev4/store/actions/connection.actions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PostBoxBody } from 'v4/components/post/body';
import { PostBoxContext, usePostBoxContext } from 'v4/components/post/context';
import { PostBoxFooter } from 'v4/components/post/footer';
import { PostBoxFooterActions } from 'v4/components/post/footer-actions';
import { PostBoxHeader } from 'v4/components/post/header';
import { PostBoxInsightPlate } from 'v4/components/post/insight-plate';
import { PostBoxBlockReportModal } from 'v4/components/post/post-actions-modals/block-report';
import { PostBoxDeleteModal } from 'v4/components/post/post-actions-modals/delete-post';
import { PostBoxNotInterestedModal } from 'v4/components/post/post-actions-modals/not-interested';
import { PostBoxPinPostModal } from 'v4/components/post/post-actions-modals/pin-post';
import { PostBoxEditModal } from './edit-post-modal';
import { PostCommentForm } from './post-comment/comment-form';
import { PostCommentRows } from './post-comment/comment-rows';
import { PostBoxQuotePostModal } from './quote-post-modal';
import { PostBoxRepostModal } from './repost-modal';
import { Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';

export const DashboardPostBox = ({
  post,
  isInFrame,
  showMedia = true,
  showComments = false,
  context,
  dashboardComments = false,
  isOnlyPreview = false,
  authorDisplayName
}) => {
  const [commentBoxVisible, setCommentBoxVisible] = useState(false);
  const [commentBoxExpanded, setCommentBoxExapnded] = useState(false);
  const router = useRouter();
  const [isFromSearch, setIsFromSearch] = useState(false);

  useEffect(() => {
    const { asPath } = router;

    const conditions = [
      'g/events?query',
      'g/articles?query',
      'g?query',
      'g/events',
      '?query', 
      'g/articles',
    ];

    const isConditionMet = conditions?.some((condition) =>
      asPath.includes(condition)
    );
    setIsFromSearch(isConditionMet);
    post?.comment_count > 0 && refetchDashboardComment?.();
  }, [router.asPath,router]);

  const postBoxContext =
    context ??
    usePostBoxContext({
      post,
      initialFetchComments: showComments,
    });

  const dispatch = useDispatch();

  const {
    post: postState,
    refetch,
    refetchComments,
    notInterestedModal,
    blockReportModal,
    pinPostModal,
    deletePostModal,
    dashboardComment,
    currentPostCommenter,
    refetchDashboardComment,
    editPostModal,
    repostModal,
    quotePostModal,
  } = postBoxContext ?? {};
  const refetchAll = () => {
    refetch?.();
    refetchComments?.();
  };
  const isEvent = post?.type === 2;
  const isArticle = post?.type === 3;

  const postModalLink = (id) => `?post=${id}`;

  const handleFollow = (user) => {
    dispatch(requestFollow());
  };

  if (postState?.removed)
    return (
      <></>
      // <HStack py={4} justifyContent="center">
      //   <Stack alignItems="center">
      //     <DeleteIcon width="20" height="20" color="rgba(0,0,0,0.3)" />
      //     <Heading color="gray.300" size="md">
      //       Post Deleted
      //     </Heading>
      //   </Stack>
      // </HStack>
    );
  if (postState?.ignored)
    return (
      <></>
      // <HStack py={4} justifyContent="center">
      //   <Stack alignItems="center">
      //     <SmileySadIcon width="20" height="20" color="rgba(0,0,0,0.3)" />
      //     <Heading color="gray.300" size="md">
      //       Post Ignored
      //     </Heading>
      //   </Stack>
      // </HStack>
    );

  return (
    <PostBoxContext.Provider
      value={{
        ...(postBoxContext ?? {}),
        commentBoxVisible,
        setCommentBoxVisible,
        commentBoxExpanded,
        setCommentBoxExapnded,
        postModalLink,
        isInFrame,
        showMedia,
        showComments,
      }}
    >
      <PostBoxHeader
        isOnlyPreview={isOnlyPreview}
        isFromSearch={isFromSearch}
      />
      <PostBoxBody isOnlyPreview={isOnlyPreview} isSearch={isFromSearch} />
      {isOnlyPreview ? (
        <Box pos="relative">
          <Box
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            zIndex="1"
          ></Box>
          <PostBoxFooter>
            <PostBoxFooterActions />
          </PostBoxFooter>
        </Box>
      ) : (
        <PostBoxFooter>
          <PostBoxInsightPlate isSearch={isFromSearch} />
          {!((isFromSearch && isEvent)) && (
            <>
              <PostBoxFooterActions />
              {commentBoxVisible ? (
                <Box px={4} borderTop="1px solid" borderColor="brandGray.500">
                  <PostCommentForm />
                </Box>
              ) : (
                ''
              )}
            </>
          )}
          {showComments ? (
            <>
              <Divider opacity={1} borderColor="gray.200" />
              <Box px={4}>
                <PostCommentRows
                  comments={postBoxContext?.comments}
                  post={post}
                  showCommentsDefault={true}
                  refetch={refetchAll}
                />
              </Box>
            </>
          ) : (
            ''
          )}
          {dashboardComments && post?.comment_count > 0 ? (
            <>
              <Divider opacity={1} borderColor="gray.200" />
              <Box px={4}>
                <PostCommentRows
                  comments={dashboardComment}
                  post={post}
                  showCommentsDefault={true}
                  refetch={refetchAll}
                  isDhasboard={true}
                />
              </Box>
            </>
          ) : (
            ''
          )}
        </PostBoxFooter>
      )}

      {notInterestedModal?.isOpen ? (
        <PostBoxNotInterestedModal {...notInterestedModal} />
      ) : (
        ''
      )}
      {blockReportModal?.isOpen ? (
        <PostBoxBlockReportModal fromDashboard={true}  post={post}  {...blockReportModal}  text={authorDisplayName} />
      ) : (
        ''
      )}
      {deletePostModal?.isOpen ? (
        <PostBoxDeleteModal {...deletePostModal} />
      ) : (
        ''
      )}
      {pinPostModal?.isOpen ? <PostBoxPinPostModal {...pinPostModal} /> : ''}
      {editPostModal?.isOpen ? <PostBoxEditModal {...editPostModal} /> : ''}
      {repostModal?.isOpen ? <PostBoxRepostModal {...repostModal} /> : ''}
      {quotePostModal?.isOpen ? (
        <PostBoxQuotePostModal {...quotePostModal} />
      ) : (
        ''
      )}
    </PostBoxContext.Provider>
  );
};
