import { Button } from '@mybridge/ui/button';
import { HStack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { PostBoxContext } from 'v4/components/post/context';

export const PostBoxRepostModal = ({ ...props }) => {
  const dispatch = useDispatch();

  const { repostModal, repostPost, repostLoading } = useContext(PostBoxContext);

  return (
    <Modal size="2xl" {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Repost</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          Are you sure you want to repost this post on your timeline?
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button
              variant="secondary"
              onClick={(e) => repostModal?.onClose?.()}
            >
              Cancel
            </Button>
            <Button
              isLoading={repostLoading}
              onClick={(e) => repostPost()}
              variant="primary"
            >
              Repost
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
